<template>
  <section>

    <div v-if="isLoading">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
    <div v-else>
      <b-input class="search-box margin-bottom--lg mt-3" placeholder="Search for ticket..." type="search" icon="search"
        v-model="searchQuery"></b-input>

      <div v-if="Tickets.length > 0">
        <div v-for="ticket in filteredEvents" :key="ticket.id">
          <div class="columns margin-bottom--md">
            <router-link class="column
              is-three-quarters-tablet
              is-four-fifths-desktop" :to="{ name: 'details', params: { id: ticket.id } }" target="_blank">
              {{ ticket.title }}
            </router-link>
            <div class="buttons column">
              <b-button type="is-danger is-light" icon-left="trash" @click.prevent="deleteTicket(ticket)">
              </b-button>

              <b-button icon-left="pencil-alt" type="is-primary is-light" @click="editTicket(ticket.id)">
              </b-button>

              <b-button v-if="ticket.isVisible" type="is-light" icon-left="eye-slash" @click.prevent="hideTicket(ticket.id)">
              </b-button>
              <b-button v-if="!ticket.isVisible" icon-left="eye" type="is-success" @click.prevent="showTicket(ticket.id)">
              </b-button>
            </div>
          </div>
        </div>
        <span v-if="!filteredEvents.length">There's no ticket with that name.</span>
      </div>

      <EmptyTour v-else />
    </div>
  </section>
</template>
<script>
import { db } from "../../firebase";
import { mapState, mapMutations } from "vuex";
import { TicketsStates, TicketsMutations } from "../../store/Storetypes";
import EmptyTour from "../../components/elements/EmptyTour.vue";
export default {
  components: {
    EmptyTour
  },
  data() {
    return {
      Tickets: [],
      searchQuery: "",
      isLoading: true,
      activeTab: 0,
    };
  },
  beforeCreate() {
    if (this.tickets) {
      this.Tickets = this.tickets
      this.isLoading = false
      return
    }
    db.firestore()
      .collection("tickets")
      .onSnapshot((snapshotChange) => {
        this.Tickets = [];
        snapshotChange.forEach((doc) => {
          this.Tickets.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        this.isLoading = false
      });
  },
  computed: {
    filteredEvents() {
      const filteredResults = this.Tickets.filter((ticket) =>
        ticket.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      return filteredResults;
    },
    ...mapState({
      tickets: state => state.tickets[TicketsStates.EVENTS]
    })
  },
  methods: {
    ...mapMutations({
      deleteTicketFromVuex: TicketsMutations.DELETE_TICKET
    }),
    editTicket(eventId) {
      this.$router.push({ name: "edit-ticket", params: { id: eventId } });
    },
    deleteTicket(ticket) {
      this.$buefy.dialog.confirm({
        title: "Deleting Ticket",
        message:
          "Are you sure you want to <b>delete</b> this ticket? This action cannot be undone.",
        confirmText: "Delete Ticket",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          ticket.galleryImages.push(ticket.coverImage);

          ticket.galleryImages.map((img) => {
            db.storage()
              .refFromURL(img)
              .delete()
              .then(() => { })
              .catch((error) => {
                console.log(error);
              });
          });
          db.firestore()
            .collection("tickets")
            .doc(ticket.id)
            .delete()
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message: "Ticket deleted!",
                position: "is-bottom",
              });

              let filteredEvents = [];
              this.Tickets.forEach((el) => {
                if (el.id !== ticket.id) {
                  filteredEvents.push(el);
                }
              });
              this.Tickets = filteredEvents;
              this.deleteTicketFromVuex(ticket.id)
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error deleting this ticket. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
    hideTicket(id) {
      this.$buefy.dialog.confirm({
        title: "Hide Ticket",
        message:
          "Are you sure you want to <b>hide</b> this ticket? The data will still be saved but user's won't be able to see it.",
        confirmText: "Hide Ticket",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          db.firestore()
            .collection("tickets")
            .doc(id)
            .update({ isVisible: false })
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message: "Ticket hidden!",
                position: "is-bottom",
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error hidding this ticket. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
    showTicket(id) {
      this.$buefy.dialog.confirm({
        title: "Make ticket available",
        message: "Do you want to make the ticket available to all users?",
        confirmText: "Yes",

        onConfirm: () => {
          db.firestore()
            .collection("tickets")
            .doc(id)
            .update({ isVisible: true })
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message: "Ticket is now available!",
                position: "is-bottom",
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error making this ticket available. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.code-container {
  display: flex;
  background-color: #F7F5FB;
  padding: 6px 16px;
  border-radius: 4px;
  width: max-content;
}

.title {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .modal .animation-content {
    width: 90%;
  }
}

@media (min-width: 776px) {
  .buttons {
    text-align-last: end;
  }
}
</style>
  