<template>
  <section>
    <img src="../../assets/search.png" />

    <h4 class="title is-5">Humm... you haven't created a tour yet</h4>
    <div v-if="accountActivated">
       <router-link to="/create">
      <b-button type="is-primary">Create a tour</b-button>
    </router-link>
    </div>
    
   
  </section>
</template>

<script>
import { db } from "../../firebase";
export default {
  data(){
    return{
      accountActivated: db.auth().currentUser.emailVerified
    }
    
  },


}
</script>


<style scoped>
section{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
}

img {
    height: auto;
    width: 264px;
    margin-bottom: 57px;
}

.title.is-5{
    margin-bottom: 48px;
}
</style>
