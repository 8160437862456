<template>
    <section>
        
    <div v-if="isLoading">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      </div>
      <div v-else>
        
          <b-input class="search-box margin-bottom--lg mt-3" placeholder="Search for activity..." type="search" icon="search"
            v-model="searchQuery"></b-input>
  
          <div v-if="Tours.length > 0">
            <div v-for="tour in filteredTours" :key="tour.id">
              <div class="columns margin-bottom--md">
                <router-link class="column
              is-three-quarters-tablet
              is-four-fifths-desktop" :to="{ name: 'details', params: { id: tour.id } }" target="_blank">
                  {{ tour.title }}
                </router-link>
                <div class="buttons column">
                  <b-button type="is-danger is-light" icon-left="trash" @click.prevent="deleteTour(tour)">
                  </b-button>
  
                  <b-button icon-left="pencil-alt" type="is-primary is-light" @click="editTour(tour.id)">
                  </b-button>
  
                  <b-button v-if="tour.isVisible" type="is-light" icon-left="eye-slash" @click.prevent="hideTour(tour.id)">
                  </b-button>
                  <b-button v-if="!tour.isVisible" icon-left="eye" type="is-success" @click.prevent="showTour(tour.id)">
                  </b-button>
                </div>
              </div>
            </div>
            <span v-if="!filteredTours.length">There's no activity with that name.</span>
          </div>
  
          <EmptyTour v-else />
      </div>
    </section>
  </template>
  <script>
  import { db } from "../../firebase";
  import { mapState, mapMutations } from "vuex";
  import { ActivitiesStates, ActivitiesMutations } from "../../store/Storetypes";
  import EmptyTour from "../../components/elements/EmptyTour.vue";
  export default {
    components: {
      EmptyTour
    },
    data() {
      return {
        Tours: [],
        searchQuery: "",
        isLoading: true,
        activeTab: 0,
      };
    },
    beforeCreate() {
      if (this.activities) {
        this.Tours = this.activities
        this.isLoading = false
        return
      }
      db.firestore()
        .collection("tours")
        .onSnapshot((snapshotChange) => {
          this.Tours = [];
          snapshotChange.forEach((doc) => {
            this.Tours.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          this.isLoading = false
        });
    },
    computed: {
      filteredTours() {
        const filteredResults = this.Tours.filter((tour) =>
          tour.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
        return filteredResults;
      },
      ...mapState({
        activities: state => state.activities[ActivitiesStates.ACTIVITIES]
      })
    },
    methods: {
      ...mapMutations({
        deleteTourFromVuex: ActivitiesMutations.DELETE_ACTIVITY
      }),
      editTour(tourId) {
        this.$router.push({ name: "edit-activity", params: { id: tourId } });
      },
      deleteTour(tour) {
        this.$buefy.dialog.confirm({
          title: "Deleting Activity",
          message:
            "Are you sure you want to <b>delete</b> this activity? This action cannot be undone.",
          confirmText: "Delete Activity",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            tour.galleryImages.push(tour.coverImage);
  
            tour.galleryImages.map((img) => {
              db.storage()
                .refFromURL(img)
                .delete()
                .then(() => { })
                .catch((error) => {
                  console.log(error);
                });
            });
            db.firestore()
              .collection("tours")
              .doc(tour.id)
              .delete()
              .then(() => {
                this.$buefy.toast.open({
                  duration: 10000,
                  message: "Activity deleted!",
                  position: "is-bottom",
                });
  
                let filteredTours = [];
                this.Tours.forEach((el) => {
                  if (el.id !== tour.id) {
                    filteredTours.push(el);
                  }
                });
                this.Tours = filteredTours;
                this.deleteTourFromVuex(tour.id)
              })
              .catch(() => {
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error deleting this activity. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          },
        });
      },
      hideTour(id) {
        this.$buefy.dialog.confirm({
          title: "Hide Activity",
          message:
            "Are you sure you want to <b>hide</b> this activity? The data will still be saved but user's won't be able to see it.",
          confirmText: "Hide Activity",
          type: "is-primary",
          hasIcon: true,
          onConfirm: () => {
            db.firestore()
              .collection("tours")
              .doc(id)
              .update({ isVisible: false })
              .then(() => {
                this.$buefy.toast.open({
                  duration: 10000,
                  message: "Activity hidden!",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error hidding this activity. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          },
        });
      },
      showTour(id) {
        this.$buefy.dialog.confirm({
          title: "Make activity available",
          message: "Do you want to make the activity available to all users?",
          confirmText: "Yes",
  
          onConfirm: () => {
            db.firestore()
              .collection("tours")
              .doc(id)
              .update({ isVisible: true })
              .then(() => {
                this.$buefy.toast.open({
                  duration: 10000,
                  message: "Activity is now available!",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error making this activity available. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          },
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .code-container {
    display: flex;
    background-color: #F7F5FB;
    padding: 6px 16px;
    border-radius: 4px;
    width: max-content;
  }
  
  .title {
    margin-bottom: 0;
  }
  
  @media screen and (max-width: 768px) {
    .modal .animation-content {
      width: 90%;
    }
  }
  
  @media (min-width: 776px) {
    .buttons {
      text-align-last: end;
    }
  }
  
  </style>
  