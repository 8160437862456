<template>
  <section>

    <div v-if="isLoading">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
    <div v-else>
      <b-input class="search-box margin-bottom--lg mt-3" placeholder="Search for event..." type="search" icon="search"
        v-model="searchQuery"></b-input>

      <div v-if="Events.length > 0">
        <div v-for="event in filteredEvents" :key="event.id">
          <div class="columns margin-bottom--md">
            <router-link class="column
              is-three-quarters-tablet
              is-four-fifths-desktop" :to="{ name: 'details', params: { id: event.id } }" target="_blank">
              {{ event.title }}
            </router-link>
            <div class="buttons column">
              <b-button type="is-danger is-light" icon-left="trash" @click.prevent="deleteEvent(event)">
              </b-button>

              <b-button icon-left="pencil-alt" type="is-primary is-light" @click="editTour(event.id)">
              </b-button>

              <b-button v-if="event.isVisible" type="is-light" icon-left="eye-slash" @click.prevent="hideEvent(event.id)">
              </b-button>
              <b-button v-if="!event.isVisible" icon-left="eye" type="is-success" @click.prevent="showEvent(event.id)">
              </b-button>
            </div>
          </div>
        </div>
        <span v-if="!filteredEvents.length">There's no event with that name.</span>
      </div>

      <EmptyTour v-else />
    </div>
  </section>
</template>
<script>
import { db } from "../../firebase";
import { mapState, mapMutations } from "vuex";
import { EventsStates, EventsMutations } from "../../store/Storetypes";
import EmptyTour from "../../components/elements/EmptyTour.vue";
export default {
  components: {
    EmptyTour
  },
  data() {
    return {
      Events: [],
      searchQuery: "",
      isLoading: true,
      activeTab: 0,
    };
  },
  beforeCreate() {
    if (this.events) {
      this.Events = this.events
      this.isLoading = false
      return
    }
    db.firestore()
      .collection("events")
      .onSnapshot((snapshotChange) => {
        this.Events = [];
        snapshotChange.forEach((doc) => {
          this.Events.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        this.isLoading = false
      });
  },
  computed: {
    filteredEvents() {
      const filteredResults = this.Events.filter((event) =>
        event.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      return filteredResults;
    },
    ...mapState({
      events: state => state.events[EventsStates.EVENTS]
    })
  },
  methods: {
    ...mapMutations({
      deleteEventFromVuex: EventsMutations.DELETE_EVENT
    }),
    editTour(eventId) {
      this.$router.push({ name: "edit-event", params: { id: eventId } });
    },
    deleteEvent(event) {
      this.$buefy.dialog.confirm({
        title: "Deleting Event",
        message:
          "Are you sure you want to <b>delete</b> this event? This action cannot be undone.",
        confirmText: "Delete Event",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          event.galleryImages.push(event.coverImage);

          event.galleryImages.map((img) => {
            db.storage()
              .refFromURL(img)
              .delete()
              .then(() => { })
              .catch((error) => {
                console.log(error);
              });
          });
          db.firestore()
            .collection("events")
            .doc(event.id)
            .delete()
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message: "Event deleted!",
                position: "is-bottom",
              });

              let filteredEvents = [];
              this.Events.forEach((el) => {
                if (el.id !== event.id) {
                  filteredEvents.push(el);
                }
              });
              this.Events = filteredEvents;
              this.deleteEventFromVuex(event.id)
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error deleting this event. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
    hideEvent(id) {
      this.$buefy.dialog.confirm({
        title: "Hide Event",
        message:
          "Are you sure you want to <b>hide</b> this event? The data will still be saved but user's won't be able to see it.",
        confirmText: "Hide Event",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          db.firestore()
            .collection("events")
            .doc(id)
            .update({ isVisible: false })
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message: "Event hidden!",
                position: "is-bottom",
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error hidding this event. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
    showEvent(id) {
      this.$buefy.dialog.confirm({
        title: "Make event available",
        message: "Do you want to make the event available to all users?",
        confirmText: "Yes",

        onConfirm: () => {
          db.firestore()
            .collection("events")
            .doc(id)
            .update({ isVisible: true })
            .then(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message: "Event is now available!",
                position: "is-bottom",
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                duration: 10000,
                message:
                  "There has been an error making this event available. Try again later.",
                position: "is-bottom",
                type: "is-danger",
              });
            });
        },
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.code-container {
  display: flex;
  background-color: #F7F5FB;
  padding: 6px 16px;
  border-radius: 4px;
  width: max-content;
}

.title {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .modal .animation-content {
    width: 90%;
  }
}

@media (min-width: 776px) {
  .buttons {
    text-align-last: end;
  }
}
</style>
  