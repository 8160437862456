<template>
  <section>
    <section class="display-flex-space-btw">
      <p class="title is-5">Manage </p>
      <b-dropdown aria-role="list" :mobile-modal="false" position="is-bottom-left">
        <template #trigger>
          <b-button icon-left="plus" class="add-btn" type="is-light" rounded></b-button>
        </template>


        <b-dropdown-item aria-role="listitem">
          <router-link :to="{ name: 'add-activity' }">
            New activity
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <router-link :to="{ name: 'create-event' }">
            New event
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <router-link :to="{ name: 'create-ticket' }">
            New ticket
          </router-link>
        </b-dropdown-item>
      </b-dropdown>

    </section>



    <b-tabs v-model="activeTab" class="mt-5">
      <b-tab-item label="Activities">
        <ManageActivities />
      </b-tab-item>

      <b-tab-item label="Events">
        <ManageEvents />
      </b-tab-item>

      <b-tab-item label="Tickets">
        <ManageTickets/>
      </b-tab-item>

    </b-tabs>
  </section>
</template>
<script>
import ManageActivities from '@/Admin/components/ManageActivities'
import ManageEvents from './components/ManageEvents.vue';
import ManageTickets from './components/ManageTickets.vue';
export default {
  components: {
    ManageActivities,
    ManageEvents,
    ManageTickets
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.title{
  margin-bottom: 0;
}

::v-deep {
  .add-btn {
    background-color: rgba(235,251,244,.4509803922);
    color: #0e3d4d;
    width: 3.5em;
    height: 3.5em;
  }
}
</style>

